<template>
  <div id="storageEdit">
    <Drawer
      :mask-closable="false"
      :title="id ? '详情' : '新增'"
      :transfer="false"
      @on-close="close"
      class="bk-sub-drawer storage-edit-drawer"
      v-model="flag"
      width="600"
    >
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="bk-drawer-body">
        <Form
          :label-width="110"
          :model="formd"
          :rules="rules"
          @submit.native.prevent
          inline
          ref="form"
        >
          <FormItem label="仓库名称：" prop="Name" required>
            <Input v-if="ABLE" v-model="formd.Name" />
            <div v-else>{{formd.Name}}</div>
          </FormItem>
          <FormItem label="编码：">
            <Input v-if="ABLE" v-model="formd.Code" />
            <div v-else>{{formd.Code}}</div>
          </FormItem>
          <FormItem label="英文名称：">
            <Input v-if="ABLE" v-model="formd.NameEn" />
            <div v-else>{{formd.NameEn}}</div>
          </FormItem>
          <FormItem label="简称：">
            <Input v-if="ABLE" v-model="formd.ShortName" />
            <div v-else>{{formd.ShortName}}</div>
          </FormItem>
          <FormItem label="部门：">
            <Select @on-change="onDept" clearable label-in-value v-if="ABLE" v-model="formd.DeptId">
              <Option :key="item.Id" :value="item.Id" v-for="item in deptLst">{{ item.DeptName }}</Option>
            </Select>
            <div v-else>{{formd.DeptName}}</div>
          </FormItem>
          <FormItem label="管理员：">
            <Input
              :value="formd.PrincipalName"
              @on-clear="unPickUser"
              clearable
              readonly
              v-if="ABLE"
            >
              <Button @click="toPickUser" icon="ios-more" slot="append"></Button>
            </Input>
            <div v-else>{{formd.PrincipalName}}</div>
          </FormItem>
          <FormItem class="long" label="位置：">
            <Input v-if="ABLE" v-model="formd.Position" />
            <div v-else>{{formd.Position}}</div>
          </FormItem>
          <FormItem class="long" label="备注：">
            <Input type="textarea" v-if="ABLE" v-model="formd.Remark" />
            <div v-else>{{formd.Remark}}</div>
          </FormItem>
        </Form>
      </div>
      <div class="bk-drawer-footer" v-if="ABLE">
        <Button :loading="posting" @click="onOk" type="primary">保存</Button>
      </div>
    </Drawer>
    <pick-user @on-ok="onPickUser" ref="PickUser" type="1" />
  </div>
</template>
<script>
import PickUser from "../../components/PickUser.vue";
export default {
  components: { PickUser },
  data() {
    return {
      ABLE: false,
      flag: false,
      id: 0,
      formd: {},
      rules: {
        Type: [{ required: true, type: "number", message: " " }],
        Status: [{ required: true, type: "number", message: " " }],
        Name: [{ required: true, message: " " }],
      },
      loading: false,
      failed: false,
      posting: false,
      deptLst: [],
    };
  },
  methods: {
    open(id) {
      this.flag = true;
      if (id) {
        this.ABLE = this.$able.map.WmsStorageUpd;
        this.id = id;
        this.req();
      } else {
        this.ABLE = this.$able.map.WmsStorageAdd;
      }
      this.reqDept();
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/WmsStorage/GetByID/" + this.id)
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.formd = res.data;
          } else {
            this.failed = true;
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    close() {
      this.ABLE = false;
      this.flag = false;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = {};
      this.id = 0;
    },
    onOk() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      this.posting = true;
      let api = this.id > 0 ? "/Api/WmsStorage/Update" : "/Api/WmsStorage/Add";
      this.$axios
        .post(api, this.formd)
        .then((res) => {
          if (res.result) {
            this.$Message.success(this.id > 0 ? "编辑成功" : "新增成功");
            this.$emit(this.id > 0 ? "on-upd" : "on-add");
            this.close();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
          this.posting = false;
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    // 部门
    reqDept() {
      this.$axios.get("/Api/OrgDept/QueryAll").then((res) => {
        if (res.result) {
          this.deptLst = res.data;
        }
      });
    },
    onDept(obj) {
      if (obj) {
        this.$set(this.formd, "DeptName", obj.label);
      } else {
        this.$set(this.formd, "DeptName", "");
      }
    },
    // 选择人员
    toPickUser() {
      let def = [];
      if (this.formd.PrincipalId) {
        def = [
          {
            Id: this.formd.PrincipalId,
            UserName: this.formd.PrincipalName,
          },
        ];
      }
      this.$refs.PickUser.open(def);
    },
    onPickUser(lst) {
      if (lst[0]) {
        this.formd.PrincipalId = lst[0].Id;
        this.$set(this.formd, "PrincipalName", lst[0].UserName);
      } else {
        this.formd.PrincipalId = "";
        this.$set(this.formd, "PrincipalName", "");
      }
    },
    unPickUser() {
      this.formd.PrincipalId = "";
      this.$set(this.formd, "PrincipalName", "");
    },
  },
};
</script>
<style lang="less">
.storage-edit-drawer {
  .ivu-form-item {
    width: 50%;
    margin-right: 0;
  }
  .ivu-form-item.long {
    width: 100%;
  }
}
</style>